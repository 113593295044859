import Repository from "./Repository";

export default {
  initAccount (idToken, userId, mail) {
    const params = { userId, mail };
    const config = {
      headers: {
        idToken: idToken,
      },
    };
    return Repository.post(`/shops/account`, params, config);
  },
}
