import moment from 'moment';
import { ORDER_STATUS } from '../constants'

/**
 * 売上管理 注文 合計金額計算
 * @param {*} orders 注文履歴
 * @returns 
 */
export const calcOrdersTotal = (orders) => {
  return _sumOrdersAmount(orders)
}

/**
 * 売上管理 グラフ用データ作成 (日)
 * @param {*} chartParams {orders, fromYMD, toYMD}
 * @returns 
 */
export const createChartDataByDate = (chartParams) => {
  const groupKey = '_key_ymd'
  return _createChartData(chartParams, groupKey, _createLabelsByDate, _fillEmptyOrdersByDate)
}

/**
 * 売上管理 グラフ用データ作成 (週)
 * @param {*} chartParams {orders, fromYMD, toYMD}
 * @returns 
 */
export const createChartDataByWeek = (chartParams) => {
  const groupKey = '_key_weekNo'
  return _createChartData(chartParams, groupKey, _createLabelsByWeek, _fillEmptyOrdersByWeek)
}

/**
 * 売上管理 グラフ用データ作成 (月)
 * @param {*} chartParams {orders, fromYMD, toYMD}
 * @returns 
 */
export const createChartDataByMonth = (chartParams) => {
  const groupKey = '_key_ym'
  return _createChartData(chartParams, groupKey, _createLabelsByMonth, _fillEmptyOrdersByMonth)
}

export const _createChartData = (chartParams, groupKey, createLabels, fillEmptyData) => {
  // chartParams: {orders, fromYMD, toYMD}
  const orders = chartParams.orders;

  // groupBy
  const datas = _groupBy(groupKey)(orders)
  // console.log("#datas", datas)

  const amountMap = Object.entries(datas).map(([key, value]) => {
    // console.log(key, value)
    const sumAmountBefore = _sumOrdersAmount(value, 0)
    const sumAmountAfter = _sumOrdersAmount(value, 1)
    return {
      key,
      amountBefore: sumAmountBefore,
      amountAfter: sumAmountAfter,
    }
  })
  // console.log("#amountMap", amountMap)

  // fill (empty data)
  fillEmptyData(amountMap, chartParams.fromYMD, chartParams.toYMD)

  const labels = createLabels(amountMap)
  const series0 = amountMap.map(obj => {
    return {
      meta: "先払い",
      value: obj.amountBefore,
    }
  })
  const series1 = amountMap.map(obj => {
    return {
      meta: "後払い",
      value: obj.amountAfter,
    }
  })
  // console.log("#chartData", labels, series0, series1)
  return {
    labels,
    series: [series0, series1]
  }
}

export const _createLabelsByDate = (amountMap) => {
  const labels = amountMap.map(obj => moment(obj.key).format('M/D'))
  return labels;
}

export const _createLabelsByWeek = (amountMap) => {
  const labels = amountMap.map(obj => moment().week(obj.key).startOf('week').format('M/D 週'))
  return labels;
}

export const _createLabelsByMonth = (amountMap) => {
  // 「2022年」を改行表示したいけどできない
  const labels = amountMap.map(obj => moment(obj.key).format('YYYY/M'))
  return labels;
}

export const _fillEmptyOrdersByDate = (amountMap, fromYMD, toYMD) => {
  // console.log("fill(date)", amountMap, fromYMD, toYMD);
  const from = moment(fromYMD, 'YYYYMMDD');
  const to = moment(toYMD, 'YYYYMMDD');
  for (let target = from.clone(); target.isSameOrBefore(to); target.add(1, 'days')) {
    const targetYMD = target.format('YYYYMMDD');
    if (!amountMap.some(m => m.key == targetYMD)) {
      amountMap.push({
        key: targetYMD,
        amountBefore: 0,
        amountAfter: 0,
      });
    }
  }

  // sort (asc)
  amountMap.sort((m1, m2) => (m1.key - m2.key));
}

export const _fillEmptyOrdersByWeek = (amountMap, fromYMD, toYMD) => {
  // console.log("fill(week)", amountMap, fromYMD, toYMD);
  const from = moment(fromYMD, 'YYYYMMDD');
  const to = moment(toYMD, 'YYYYMMDD');
  for (let target = from.clone(); target.isSameOrBefore(to); target.add(1, 'weeks')) {
    const targetWeekNo = target.week();
    if (!amountMap.some(m => m.key == targetWeekNo)) {
      amountMap.push({
        key: targetWeekNo,
        amountBefore: 0,
        amountAfter: 0,
      });
    }
  }

  // sort (asc)
  amountMap.sort((m1, m2) => (m1.key - m2.key));
}

export const _fillEmptyOrdersByMonth = (amountMap, fromYMD, toYMD) => {
  // console.log("fill(month)", amountMap, fromYMD, toYMD);
  const from = moment(fromYMD, 'YYYYMMDD');
  const to = moment(toYMD, 'YYYYMMDD');
  for (let target = from.clone(); target.isSameOrBefore(to); target.add(1, 'months')) {
    const targetYM = target.format('YYYY/M');
    if (!amountMap.some(m => m.key == targetYM)) {
      amountMap.push({
        key: targetYM,
        amountBefore: 0,
        amountAfter: 0,
      });
    }
  }

  // sort (asc)
  amountMap.sort((m1, m2) => {
    const _m1 = moment(m1.key, 'YYYY/M');
    const _m2 = moment(m2.key, 'YYYY/M');
    if (_m1.isBefore(_m2)) {
      return -1;
    } else if (_m1.isAfter(_m2)) {
      return 1;
    } else {
      return 0;
    }
  });
}

// https://gist.github.com/JamieMason/0566f8412af9fe6a1d470aa1e089a752
export const _groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

export const _sumOrdersAmount = (orders, wayOfPay) => {
  // 受取済のもののみ (売上対象)
  const completedOrders =
    orders.filter(order => order.orderStatus === ORDER_STATUS.RECEIVED) // orderStatus (3:受取済)

  // all
  if (wayOfPay === undefined || !Number.isInteger(wayOfPay)) {
    return completedOrders
      .reduce((sum, order) => { return sum + order.amount }, 0)
  }

  // filter
  return completedOrders
    .filter(order => order.wayOfPay === wayOfPay) // wayOfPay (0:先払い 1:後払い)
    .reduce((sum, order) => { return sum + order.amount }, 0)
}
