import Vue from 'vue'
import Router from 'vue-router'
import { auth } from "./plugins/firebase";

Vue.use(Router)

const routes = [
  {
    path: "/login",
    component: () => import('@/views/Login'),
  },
  {
    path: "/reset",
    component: () => import('@/views/PasswordReset'),
  },
  {
    path: "/auth/create",
    component: () => import('@/views/AuthCreate'),
  },
  {
    path: "/auth/phone",
    component: () => import('@/views/AuthPhone'),
  },
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    meta: { auth: true },
    children: [
      {
        name: 'メニュー',
        path: "",
        component: () => import('@/views/dashboard/MenuList'),
        meta: { auth: true }
      },
      {
        name: 'メニュー',
        path: "menu",
        component: () => import('@/views/dashboard/MenuList'),
        meta: { auth: true }
      },
      {
        name: 'メニュー',
        path: "menu/new",
        component: () => import('@/views/dashboard/Menu'),
        meta: { auth: true }
      },
      {
        name: 'メニュー',
        path: "menu/edit/:menuId",
        component: () => import('@/views/dashboard/Menu'),
        meta: { auth: true }
      },
      {
        name: 'カテゴリ',
        path: "category",
        component: () => import('@/views/dashboard/CategoryList'),
        meta: { auth: true }
      },
      {
        name: 'オプション',
        path: "option",
        component: () => import('@/views/dashboard/OptionList'),
        meta: { auth: true }
      },
      {
        name: 'オプション',
        path: "option/new",
        component: () => import('@/views/dashboard/Option'),
        meta: { auth: true }
      },
      {
        name: 'オプション',
        path: "option/edit/:optionId",
        component: () => import('@/views/dashboard/Option'),
        meta: { auth: true }
      },
      {
        name: '店舗情報',
        path: "about",
        component: () => import('@/views/dashboard/About'),
        meta: { auth: true }
      },
      {
        name: '営業設定',
        path: "business-settings",
        component: () => import('@/views/dashboard/BusinessSettings'),
        meta: { auth: true }
      },
      {
        name: 'オンライン決済',
        path: "onlinepayment",
        component: () => import('@/views/dashboard/OnlinePayment'),
        meta: { auth: true }
      },
      {
        name: '特定商取引法',
        path: "law",
        component: () => import('@/views/dashboard/Law'),
        meta: { auth: true }
      },
      {
        name: '売上管理',
        path: "sales",
        component: () => import('@/views/dashboard/SalesManagement'),
        meta: { auth: true }
      },
      // Test
      {
        name: 'Test',
        path: 'test',
        // component: () => import('@/views/dashboard/Dashboard'),
        component: () => import('@/views/test/GraphTest'),
        // meta: { auth: true },
      },
      // Pages
      {
        name: 'User Profile',
        path: 'pages/user',
        component: () => import('@/views/dashboard/pages/UserProfile'),
        meta: { auth: true },
      },
      {
        name: 'Notifications',
        path: 'components/notifications',
        component: () => import('@/views/dashboard/component/Notifications'),
      },
      {
        name: 'Icons',
        path: 'components/icons',
        component: () => import('@/views/dashboard/component/Icons'),
      },
      {
        name: 'Typography',
        path: 'components/typography',
        component: () => import('@/views/dashboard/component/Typography'),
      },
      // Tables
      {
        name: 'Regular Tables',
        path: 'tables/regular-tables',
        component: () => import('@/views/dashboard/tables/RegularTables'),
      },
      // Maps
      {
        name: 'Google Maps',
        path: 'maps/google-maps',
        component: () => import('@/views/dashboard/maps/GoogleMaps'),
      },
      // Upgrade
      {
        name: 'Upgrade',
        path: 'upgrade',
        component: () => import('@/views/dashboard/Upgrade'),
      },
    ],
  },
];

/* eslint-disable */
const router = new Router({
  routes,
  // mode: "hash",
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition);
        });
      });
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // 認証が必要かどうか？
  const requiresAuth = to.matched.some((record) => record.meta.auth);
  if (!requiresAuth) {
    next(); //認証不要
    return;
  }

  // Firebase Auth ユーザ情報
  // reload すると auth ユーザ情報消える
  // const user = auth.currentUser;
  // console.log(`user=${user}`);
  // if (!user) {
  //   next({
  //     path: "/login",
  //     query: { redirect: to.fullPath },
  //   });
  // } else {
  //   next();
  // }

  // Firebase Auth ユーザ情報
  auth.onAuthStateChanged((user) => {
    // ログイン済 かつ SMS 認証済
    const phoneNumber = user?.phoneNumber ?? null;
    if (user && phoneNumber) {
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  });
});
/* eslint-enable */

export default router;
