// v8, v9 混在 (いずれ v9 完全移行必須)
// https://firebase.google.com/docs/web/modular-upgrade#example_3_combining_version_8_and_version_9_code_styles
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

// const production = true;
const production = process.env.VUE_APP_NODE_ENV === 'production';

let firebaseConfig = {}
if (production) {
  firebaseConfig = {
    apiKey: "AIzaSyCfBDXdpMeEfE0c0BAIIbglni2_GW4PgVk",
    authDomain: "avt-nobunaga-261b3.firebaseapp.com",
    databaseURL: "https://avt-nobunaga-261b3.firebaseio.com",
    projectId: "avt-nobunaga-261b3",
    storageBucket: "avt-nobunaga-261b3.appspot.com",
    messagingSenderId: "672227547638",
    appId: "1:672227547638:web:91b26cad3f00147a1a4fd7",
    measurementId: "G-X3308JQ9LL"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCNWIG_CdKZensi9XVoG7RRkxlnZvZWSs0",
    authDomain: "avt-nobunaga-dev.firebaseapp.com",
    projectId: "avt-nobunaga-dev",
    storageBucket: "avt-nobunaga-dev.appspot.com",
    messagingSenderId: "335366356518",
    appId: "1:335366356518:web:4156cc052f81eece2f70e7"
  };
}

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage().ref();
