import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/common'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

Vue.config.productionTip = false

// firestore
import "./plugins/firebase";
import { firestorePlugin } from "vuefire";
Vue.use(firestorePlugin);

// vue-select-image
import VueSelectImage from 'vue-select-image';
require('vue-select-image/dist/vue-select-image.css');
Vue.use(VueSelectImage);

// vue-upload-multiple-image
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);

// vue-clipboard2
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// v-calendar
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
