// 画像アップロード
import {
  createUuid,
  uploadImg,
  createImgUrlWithUpload,
  createDbImgsWithUpload,
  getImgDatas,
  createImgsForVueUploadMulitpleImage,
  cleanImgs,
  dataURItoBlob,
} from "./imageUploadLogic";

// 画像 (no image)
const ID_NOIMAGE = "id_noimage";
const STORAGE_FILE_NOIMAGE = {
  id: ID_NOIMAGE,
  url: process.env.VUE_APP_URL_NOIMAGE,
  fullPath: "noimage.png",  // 共通(storage root)
};

const getDefaultState = () => ({
  userInfo: null,
  allUserInfo: [],
  imagesAbout: [], // about 画像(表示用)
  images: [], // メニュー画像(表示用)
  imagesForDb: [], // メニュー画像(DB)
  noImage: STORAGE_FILE_NOIMAGE,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    userInfo (state, value) { state.userInfo = value; },
    allUserInfo (state, value) { state.allUserInfo = value; },
    imagesAbout (state, value) { state.imagesAbout = value; },
    images (state, value) { state.images = value; },
    imagesForDb (state, value) { state.imagesForDb = value; },
  },
  getters: {
    userInfo: state => state.userInfo,
    allUserInfo: state => state.allUserInfo,
    imagesAbout: state => state.imagesAbout,
    images: state => state.images,
    imagesForDb: state => state.imagesForDb,
    noImage: state => state.noImage,
  },
  actions: {
    //------------------------------
    // 画像アップロード
    //------------------------------
    createMenuId () {
      this.commit("progress", true, { root: true });
      try {
        return createUuid();
      } catch (e) {
        console.error(e);
        return null;
      } finally {
        this.commit("progress", false, { root: true });
      }
    },
    createImageId () {
      this.commit("progress", true, { root: true });
      try {
        return createUuid();
      } catch (e) {
        console.error(e);
        return null;
      } finally {
        this.commit("progress", false, { root: true });
      }
    },
    async uploadImgFromDataURI (context, { menuId, name, dataUri }) {
      const storagePath = await this.dispatch("getStoragePath", { menuId: menuId, });
      this.commit("progress", true, { root: true });
      try {
        const blob = dataURItoBlob(dataUri);
        const file = new File([blob], name, { type: blob.type });
        return await uploadImg(storagePath, file);
      } catch (e) {
        console.error(e);
        return null;
      } finally {
        this.commit("progress", false, { root: true });
      }
    },
    async uploadImg (context, { menuId, file }) {
      this.commit("progress", true, { root: true });
      try {
        const storagePath = await this.dispatch("getStoragePath", { menuId: menuId, });
        return await uploadImg(storagePath, file);
      } catch (e) {
        console.error(e);
        return null;
      } finally {
        this.commit("progress", false, { root: true });
      }
    },
    async cleanImgs (context, { menuId }) {
      this.commit("progress", true, { root: true });
      try {
        // db + storage
        const storagePath = await this.dispatch("getStoragePath", { menuId: menuId });
        const dbPath = await this.dispatch("getImgPath", { menuId: menuId });
        console.log(`[cleanImgs]storage=${storagePath}, db=${dbPath}`);
        return await cleanImgs(storagePath, dbPath);
      } catch (e) {
        console.error(e);
      }
      this.commit("progress", false, { root: true });
    },
    async fetchMenuImgs (context, { menuId, }) {
      console.log(`#fetchMenuImgs menuId=${menuId}`);
      this.commit("progress", true, { root: true });
      try {
        const dbPath = await this.dispatch("getImgPath", { menuId: menuId });
        console.log(`[fetchMenuImgs]dbPath=${dbPath}`);

        // db
        let imgDatas = menuId ? await getImgDatas(dbPath) : [];
        this.commit("imageUpload/imagesForDb", imgDatas, { root: true });

        // 表示用
        imgDatas.unshift(STORAGE_FILE_NOIMAGE); // 先頭に追加(default)

        // vue-select-image
        // const images = createImgsForVueSelectImage(imgDatas);
        // this.commit("imageUpload/images", images, { root: true });

        // vue-upload-multiple-image
        const images = createImgsForVueUploadMulitpleImage(imgDatas);
        // console.log(`[fetchMenuImgs]images=${JSON.stringify(images)}`);
        this.commit("imageUpload/images", images, { root: true });
      } catch (e) {
        console.error(e);
      }
      this.commit("progress", false, { root: true });
    },
    async createDbImgsWithUpload (context, { menuId, imgs }) {
      this.commit("progress", true, { root: true });
      try {
        if (!imgs || imgs.length == 0) {
          console.log("empty imgs");
          return [];
        }
        const storagePath = await this.dispatch("getStoragePath", { menuId: menuId });
        const imagesForDb = context.getters.imagesForDb;
        return createDbImgsWithUpload(storagePath, imgs, imagesForDb);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false, { root: true });
      }
    },
    fetchAboutImgs (context, { imageUrl, }) {
      // console.log(`#fetchAboutImg imageUrl=${imageUrl}`);
      this.commit("progress", true, { root: true });
      try {
        if (!imageUrl) {
          // 画像未設定時は何もしない
          return;
        }

        // 表示用画像データ作成 (vue-upload-multiple-image 用)
        const imgDatas = [{
          url: imageUrl,
        }];
        const images = createImgsForVueUploadMulitpleImage(imgDatas);
        // console.log(`[fetchMenuImgs]images=${JSON.stringify(images)}`);
        this.commit("imageUpload/imagesAbout", images, { root: true });
      } catch (e) {
        console.error(e);
      } finally {
        this.commit("progress", false, { root: true });
      }
    },
    async createImgUrlWithUpload (context, { menuId, img }) {
      this.commit("progress", true, { root: true });
      try {
        if (!img) {
          console.log("empty img");
          return null;
        }
        const storagePath = await this.dispatch("getStoragePath", { menuId: menuId });
        return createImgUrlWithUpload(storagePath, img);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false, { root: true });
      }
    },
  },
}
