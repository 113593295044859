import firebase from "firebase/compat/app";
import { db } from "../../plugins/firebase";
import { v4 as uuidv4 } from "uuid";

import {
  uploadFile,
  cleanFiles,
} from "../../service/StorageService";

//==============================
// Service
//==============================
export const createUuid = () => uuidv4();

/**
 * 画像 URL 作成
 * (アップロードされていない場合はアップロード後に URL 発行)
 * @param {*} storagePath Storage フルパス
 * @param {*} imgData upload 部品表示データ
 * @returns 
 */
export const createImgUrlWithUpload = async (storagePath, imgData,) => {
  const path = imgData.path;
  if (path.startsWith("data:")) {
    // path が data URI -> ローカルファイル(アップロード対象)
    // [Storage] 画像アップロード (/menus/:menuId)
    const fileData = await uploadImgFromDataURI(
      storagePath,
      imgData.name,
      imgData.path,
    );
    return fileData.url;
  } else {
    // path が URL(https) -> アップロード済
    return imgData.path;
  }
};

/**
 * DB 登録用データ作成 (メニュー画像用)
 * (アップロードされていない場合はアップロード後に作成)
 * @param {*} storagePath Storage フルパス
 * @param {*} imgDatas upload 部品表示データ
 * @param {*} imagesForDb DBデータ(menus/menuId images[])
 * @returns 
 */
export const createDbImgsWithUpload = async (storagePath, imgDatas, imagesForDb,) => {
  return Promise.all(
    imgDatas.map(async (img) => {
      const path = img.path;
      let fileData = null;
      if (path.startsWith("data:")) {
        // path が data URI -> ローカルファイル(アップロード対象)
        // [Storage] 画像アップロード (/menus/:menuId)
        fileData = await uploadImgFromDataURI(
          storagePath,
          img.name,
          img.path,
        );
      } else {
        // path が URL(https) -> アップロード済
        // DBデータを基に fileData を作る
        const imageForDb = imagesForDb.find(
          (image) => image.id === img.id
        );
        const fullPath = imageForDb.fullPath;
        fileData = {
          fullPath: fullPath,
          url: img.path,
        };
      }

      // 画像選択状態
      const dbImg = {
        id: img.id,
        fullPath: fileData.fullPath,
        url: fileData.url,
        selected: img.default === 1, // 部品の選択状態(default:1)
      };
      // console.log(`[register]dbImg=${JSON.stringify(dbImg)}`);
      return dbImg;
    })
  );
};

export const uploadImgFromDataURI = async (storagePath, name, dataUri) => {
  try {
    const blob = dataURItoBlob(dataUri);
    const file = new File([blob], name, { type: blob.type });
    return await uploadImg(storagePath, file);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const dataURItoBlob = (dataURI) => {
  // https://stackoverflow.com/questions/12168909/blob-from-dataurl
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
};

export const uploadImg = async (storagePath, file) => {
  console.log(`#uploadImg: ${JSON.stringify(file)}`);
  if (!file) {
    return null;
  }

  // storage (upload)
  const fullPath = `${storagePath}/${file.name}`;
  const fileData = await uploadFile(fullPath, file);
  //----------------------------------------
  // 同一ファイルがアップロードされた場合
  // - storage -> 上書きアップロード
  //----------------------------------------
  return fileData;
};

export const cleanImgs = async (storagePath, dbPath) => {
  return Promise.all(
    [
      // DB
      cleanImgData(dbPath),

      // Storage
      cleanFiles(storagePath)
    ]
  );
}

/**
 * 画像表示用データ作成 (vue-upload-multiple-image)
 * @param {Array} imgDatas DB(users_tmp/:userId/images)
 * @returns 
 */
export const createImgsForVueUploadMulitpleImage = (imgDatas) => {
  // 選択中画像(selected:true)
  const selectedImgs = imgDatas.filter(data => data.selected).map(data => {
    return {
      id: data.id,
      path: data.url,
      // 部品上で選択状態にする
      default: 1,
      highlight: 1,
    };
  });

  // 選択中以外の画像(selected:false)
  let notSelectedImgs = imgDatas.filter(data => !data.selected).map(data => {
    return {
      id: data.id,
      path: data.url,
      // 部品上で非選択状態にする
      default: 0,
      highlight: 0,
    };
  });
  // 「selected:true」画像がない場合は、先頭要素を selected にする
  if (selectedImgs.length == 0) {
    // 必ず1つ以上要素がある前提 (no image)
    notSelectedImgs[0].default = 1;
    notSelectedImgs[0].highlight = 1;
  }

  // 「selected:true」画像を先頭に配置 (現仕様では1要素だけのはず)
  const imgs = selectedImgs.concat(notSelectedImgs);
  return imgs;
};

//==============================
// Firestore
//==============================
export const existsImgData = async (dbPath, fullPath) => {
  // db 存在チェック (同名ファイル)
  const docSS = await db.doc(`${dbPath}`).get();
  if (!docSS.exists) {
    return false;
  }
  const imgData = docSS.data().images;
  if (!imgData) {
    return false;
  }
  const matches = imgData.filter(img => img.fullPath === fullPath);
  return matches.length != 0;
};

export const getImgDatas = async (dbPath) => {
  console.log(`[db]get: ${dbPath}`);
  const docSS = await db.doc(`${dbPath}`).get();
  if (docSS.exists) {
    const images = docSS.data().images;
    return images != null ? images : [];
  } else {
    return [];
  }
};

export const getImgDataById = async (dbPath, imgId) => {
  console.log(`[db]getImgDataById: ${dbPath}, ${imgId}`);
  const docSS = await db.doc(`${dbPath}`).get();
  if (docSS.exists) {
    let images = docSS.data().images;
    images = images != null ? images : [];
    const target = images.find(img => img.id === imgId);
    return target != null ? target : {};
  } else {
    return {};
  }
};

export const updateImgDatas = async (dbPath, dbData) => {
  console.log(`[db]update: ${dbPath}, ${JSON.stringify(dbData)}`);
  return db.doc(`${dbPath}`).set({
    images: firebase.firestore.FieldValue.arrayUnion(dbData),
  }, { merge: true });
};

//TODO 指定要素を更新
export const updateImgDataSelected = async (dbPath, imgId, selected) => {
  console.log(`[db]updateImgDataSelected: ${dbPath}, ${imgId}, ${selected}`);
  // - 現在の配列データ取得
  // - 配列データ書き換え
  //   - imgId が同じ -> selected: true
  //   - imgId が違う -> selected: false
  // - Firestore に設定

  const currentData = await getImgDatas(dbPath);
  console.log(`[updateImgDataSelected]current: ${JSON.stringify(currentData)}`);
  const newData = currentData.map(data => {
    if (data.id === imgId) {
      data.selected = selected;
    } else {
      data.selected = !selected;
    }
    return data;
  });
  console.log(`[dupdateImgDataSelected]new: ${JSON.stringify(newData)}`);

  return db.doc(`${dbPath}`).set({
    // images: firebase.firestore.FieldValue.arrayUnion(newData),
    images: newData,
  }, { merge: true });
};

export const deleteImgData = async (dbPath, dbData) => {
  console.log(`[db]delete: ${dbPath},  ${JSON.stringify(dbData)}`);
  return db.doc(`${dbPath}`).set({
    images: firebase.firestore.FieldValue.arrayRemove(dbData),
  }, { merge: true });
};

export const cleanImgData = async (dbPath) => {
  console.log(`[db]clean: ${dbPath}`);
  return db.doc(`${dbPath}`).set({
    images: firebase.firestore.FieldValue.delete(),
  }, { merge: true });
};
