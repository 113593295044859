import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment';

// Firebase
import { auth, db } from "../plugins/firebase";
import moduleImageUpload from '@/store/modules/imageUpload';
import FirestoreService from "../service/FirestoreService";
import {
  // deleteFile,
  cleanFiles,
} from "../service/StorageService";

// REST
import { RepositoryFactory } from "../repository/RepositoryFactory";
const CommonRepository = RepositoryFactory.get("common");

import { DISCOUNT_STATUS, PROVIDE_STATUS, ORDER_ACCEPT_STATUS, CHART_TYPE } from "../constants";
import { createChartDataByDate, createChartDataByWeek, createChartDataByMonth, calcOrdersTotal, } from '../util/chartUtil'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    imageUpload: moduleImageUpload,
  },
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    // barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,

    debug: process.env.VUE_APP_DEBUG == "true",
    progress: false,

    // Firebase
    user: null,
    userSettings: null,
    fsService: null,
    settings: null,
    about: null,
    holidays: [],
    groups: [],
    menus: [],
    toppings: [],
    topping: null,
    discountStatusList: [],
    provideStatusList: [],
    orderAcceptStatusList: [],

    salesChart: null,
    salesTotal: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    progress (state, progress) { state.progress = progress },
    user (state, user) { state.user = user },
    setUserSettings (state, userSettings) { state.userSettings = userSettings },
    fsService (state, fsService) { state.fsService = fsService; },
    setSettings (state, settings) { state.settings = settings; },
    setAbout (state, about) { state.about = about; },
    holidays (state, holidays) { state.holidays = holidays; },
    groups (state, value) { state.groups = value },
    setMenus (state, menus) { state.menus = menus; },
    setToppings (state, toppings) { state.toppings = toppings; },
    setTopping (state, topping) { state.topping = topping; },
    discountStatusList (state, value) { state.discountStatusList = value; },
    provideStatusList (state, value) { state.provideStatusList = value; },
    orderAcceptStatusList (state, value) { state.orderAcceptStatusList = value; },

    salesChart (state, value) { state.salesChart = value; },
    salesTotal (state, value) { state.salesTotal = value; },
  },
  getters: {
    progress: state => state.progress,
    user: state => state.user,
    userSettings: state => state.userSettings,
    fsService: state => state.fsService,
    settings: state => state.settings,
    about: state => state.about,
    holidays: state => state.holidays,
    groups: state => state.groups,
    menus: state => state.menus,
    toppings: state => state.toppings,
    topping: state => state.topping,
    discountStatusList: state => state.discountStatusList,
    provideStatusList: state => state.provideStatusList,
    orderAcceptStatusList: state => state.orderAcceptStatusList,

    salesChart: state => state.salesChart,
    salesTotal: state => state.salesTotal,
  },
  actions: {
    showProgress () {
      this.commit("progress", true);
    },
    hideProgress () {
      this.commit("progress", false);
    },
    //===================================
    // Firebase Auth
    //===================================
    async createFirebaseUser (context, { email, password }) {
      this.commit("progress", true);
      try {
        // サインアウト
        await auth.signOut();

        // アカウント作成 (自動ログイン)
        await auth.createUserWithEmailAndPassword(email, password);

        // DB にアカウント作成 (settings, user)
        const authUser = auth.currentUser
        const idToken = await auth.currentUser.getIdToken();
        const userId = authUser.uid;
        const mail = authUser.email;
        await CommonRepository.initAccount(idToken, userId, mail)

        // メール送信 (本人認証)
        const actionCodeSettings = {
          url: `${process.env.VUE_APP_URL_HOST}/login`, // リダイレクト設定 (メール内リンク -> 次へ押下)
          handleCodeInApp: false,
        };
        await auth.currentUser.sendEmailVerification(actionCodeSettings);

        return true;
      } catch (e) {
        // console.error(e);
        return false;
      } finally {
        this.commit("progress", false);
      }
    },
    async loginToFirebase (context, { email, password }) {
      this.commit("progress", true);
      try {
        // await this.dispatch("sleep", { msec: 1000 });
        await auth.signInWithEmailAndPassword(email, password);

        // FirestoreService 初期化 (ユーザ情報 保存)
        await this.dispatch("initFS");

        return true;
      } catch (e) {
        console.error(e);
        //TODO エラーハンドリング
        // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error
        // if (error.code === 'auth/invalid-email') {
        //   // メールアドレスの形式がおかしい
        // } else if (error.code === 'auth/user-disabled') {
        //   // ユーザが無効になっている
        // } else if (error.code === 'auth/user-not-found') {
        //   // ユーザが存在しない
        // } else if (error.code === 'auth/wrong-password') {
        //   // パスワードが間違っている
        // } else if (error.code === 'auth/too-many-requests') {
        //   // 何度もパスワードを間違えた
        // } else {
        //   // その他
        // }
        return false;
      } finally {
        this.commit("progress", false);
      }
    },
    async logoutFromFirebase () {
      this.commit("progress", true);
      try {
        await auth.signOut();

        // ユーザ情報 クリア
        this.commit("user", null);
        this.commit("fsService", null);

        return true;
      } catch (e) {
        console.error(e);
        return null;
      } finally {
        this.commit("progress", false);
      }
    },
    async sendPasswordResetEmail (context, { email, }) {
      this.commit("progress", true);
      try {
        // 承認済みドメインエラー
        // FirebaseError: Firebase: Domain not whitelisted by project (auth/unauthorized-continue-uri).
        // 設定方法: Firebase Console > Authentication > Settings > 承認済みドメイン

        // メール送信 (パスワード再設定)
        const actionCodeSettings = {
          url: `${process.env.VUE_APP_URL_HOST}/login`, // リダイレクト
          handleCodeInApp: false,
        };
        await auth.sendPasswordResetEmail(email, actionCodeSettings)

        return true;
      } catch (e) {
        // console.error(e);
        return null;
      } finally {
        this.commit("progress", false);
      }
    },
    async isVerifiedPhoneNumber () {
      const phoneNumber = auth.currentUser.phoneNumber ?? null;
      return phoneNumber;
    },
    //===================================
    // Firestore
    //===================================
    async initFS () {
      // 初期化 (auth 情報を利用)
      const uid = auth.currentUser.uid;
      const fs = new FirestoreService(db, uid);
      await fs.init();
      this.commit("fsService", fs);

      // shop ユーザ情報 保存
      const user = fs.getUser();
      this.commit("user", user);

      // about 取得 (各種リソースのために必ず1度取得しておく)
      this.dispatch("fetchAbout");

      return fs;
    },
    async getFS (context) {
      let fs = context.getters.fsService;
      if (fs != null) {
        return fs;
      }
      return await this.dispatch("initFS");
    },
    async getShopId () {
      let user = this.getters.user;
      if (!user) {
        await this.dispatch("initFS");
        user = this.getters.user;
      }
      const shopId = user.shopId;
      return shopId;
    },
    //-----------------------------------
    // 店舗設定
    //-----------------------------------
    async fetchSettings () {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        const settings = await fsService.fetchSettings();
        // console.log(`settings=${JSON.stringify(settings)}`);
        this.commit("setSettings", settings);
      } catch (e) {
        console.error(e);
      }
      this.commit("progress", false);
    },
    async updateSettings (context, { settings }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.updateSettings(settings);

        // about 取得 (各種リソースのために必ず1度取得しておく)
        this.dispatch("fetchSettings");

      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    //-----------------------------------
    // 店舗情報
    //-----------------------------------
    async getMiniAppUrl () {
      const shopId = await this.dispatch("getShopId");
      const urlBase = process.env.VUE_APP_MINIAPP_URL_BASE;
      return `${urlBase}/${shopId}`
    },
    async fetchAbout () {
      this.commit("progress", true);
      try {
        // console.log("start");
        // await this.dispatch("sleep", { msec: 3000 });
        // console.log("end");
        const fsService = await this.dispatch("getFS");
        const about = await fsService.fetchAbout();
        // console.log(`about=${JSON.stringify(about)}`);
        if (!about.business) {
          about.business = {};
        }
        this.commit("setAbout", about);
      } catch (e) {
        console.error(e);
      }
      this.commit("progress", false);
    },
    async updateAbout (context, { about }) {
      this.commit("progress", true);
      try {
        // default 値格納
        const business = about.business;
        if (!business || business.bufferMinutes === undefined || business.stepMinutes === undefined) {
          business.bufferMinutes = 3;
          business.stepMinutes = 5;
        }

        const fsService = await this.dispatch("getFS");
        await fsService.updateAbout(about);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async fetchDefaultValuesForAbout () {
      // console.log(`fetchDefaultValuesForAbout`);
      this.commit("progress", true);
      try {
        // 受付状態
        const list = ORDER_ACCEPT_STATUS.map(s => {
          return {
            id: s.id,
            label: s.name,
          }
        });
        this.commit("orderAcceptStatusList", list);
      } catch (e) {
        console.error(e);
      }
      this.commit("progress", false);
    },
    async updateBusinessDays (context, { daysEvery, daysTemporary }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.updateBusinessDays(daysEvery, daysTemporary);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    //-----------------------------------
    // カテゴリ関係
    //-----------------------------------
    async fetchGroups () {
      // console.log(`fetchGroups`);
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        const groupsData = await fsService.fetchMenuGroups();
        let groups = [];
        if (groupsData && groupsData.groups) {
          groups = groupsData.groups;
        }
        // console.log(`groups=${JSON.stringify(groups)}`);
        this.commit("groups", groups);
      } catch (e) {
        console.error(e);
      } finally {
        this.commit("progress", false);
      }
    },
    async addGroup (context, { group }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.addGroup(group);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async deleteGroup (context, { groupId }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.deleteGroup(groupId);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async updateGroup (context, { group }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.updateGroup(group);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async updateGroupSort (context, { groups }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.updateGroupSort(groups);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    //-----------------------------------
    // メニュー関係
    //-----------------------------------
    async fetchMenus () {
      // console.log(`fetchMenus`);
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        const menus = await fsService.fetchMenus();
        // console.log(`menus=${JSON.stringify(menus)}`);
        this.commit("setMenus", menus);
      } catch (e) {
        console.error(e);
      }
      this.commit("progress", false);
    },
    async getImgPath (context, { menuId }) {
      // 画像パス (メニュー)
      // 新規 shop/:shopId/users_tmp/[auth#uid]
      // 編集 shop/:shopId/menus/:menuId
      let user = this.getters.user;
      if (!user) {
        await this.dispatch("initFS");
        user = this.getters.user;
      }
      // console.log(`[getImgPath]user=${JSON.stringify(user)}`);
      const shopsRoot = user.shopsRoot;
      const shopId = user.shopId;
      if (!menuId) {
        const uid = auth.currentUser.uid;
        return `${shopsRoot}/${shopId}/users_tmp/${uid}`;
      } else {
        return `${shopsRoot}/${shopId}/menus/${menuId}`;
      }
    },
    async createMenu (context, { menu }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.createMenu(menu);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async updateMenu (context, { menu }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");

        // old
        const menuId = menu.menuId;
        const oldMenu = await this.dispatch("fetchMenu", { menuId: menuId });
        // const oldImgs = oldMenu.images;
        // const newImgs = menu.images;
        // console.log(`[updateMenu]old=${JSON.stringify(oldImgs)}, new=${JSON.stringify(newImgs)}`);

        // menu.timestamp 更新するかどうか
        const updateMenuTimestamp = await fsService.isUpdateMenuTimestamp(oldMenu, menu);
        console.log(`updateMenuTimestamp`, updateMenuTimestamp);

        // update
        await fsService.updateMenu(menu, updateMenuTimestamp);

        //TODO 旧部品
        // delete (storage)
        // if (oldImgs != null && oldImgs.length != 0) {
        //   const newImgIds = newImgs.map(newImg => newImg.id);
        //   const targetImgs = oldImgs.filter(oldImg => !newImgIds.includes(oldImg.id));
        //   // console.log(`[updateMenu]delete=${JSON.stringify(targetImgs)}`);
        //   await Promise.all(targetImgs.map(img => deleteFile(img.fullPath)));
        // }
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async updateMenuSort (context, { groupId, menus }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.updateMenuSort(groupId, menus);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async fetchMenu (context, { menuId }) {
      // console.log(`fetchMenu: ${menuId}`);
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        const menu = await fsService.fetchMenu(menuId);
        // console.log(`menu=${JSON.stringify(menu)}`);
        return menu;
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async deleteMenu (context, { menuId }) {
      // console.log(`deleteMenu: ${menuId}`);
      this.commit("progress", true);
      try {
        // DB
        const fsService = await this.dispatch("getFS");
        await fsService.deleteMenu(menuId);

        // Storage (menus/:menuId 配下)
        const storagePath = await this.dispatch("getStoragePath", { menuId: menuId });
        return cleanFiles(storagePath);
      } catch (e) {
        console.error(e);
      } finally {
        this.commit("progress", false);
      }
    },
    async fetchDefaultValuesForMenuList () {
      // console.log(`fetchDefaultValuesForMenuList`);
      this.commit("progress", true);
      try {
        // 割引状態
        const discountStatusList = DISCOUNT_STATUS.map(s => {
          return {
            id: s.id,
            label: s.name,
          }
        });
        this.commit("discountStatusList", discountStatusList);

        // 提供状態
        const provideStatusList = PROVIDE_STATUS.map(s => {
          return {
            id: s.id,
            label: s.name,
          }
        });
        this.commit("provideStatusList", provideStatusList);
      } catch (e) {
        console.error(e);
      }
      this.commit("progress", false);
    },
    //-----------------------------------
    // オプション関係
    //-----------------------------------
    async fetchValuesForTopping (context, { toppingId }) {
      this.commit("progress", true);
      try {
        // 初期化
        this.commit("setMenus", []);
        this.commit("setTopping", null);

        const fsService = await this.dispatch("getFS");

        // menus グループをばらしてフラットに整列)
        let menusData = await fsService.fetchMenus();
        console.log(`menus(before)`, menusData);
        let flatMenus = menusData.flatMap(data => {
          return data.menus;
        })
        const distinctMenus = [...new Set(flatMenus)];
        console.log(`menus(after)`, distinctMenus);
        this.commit("setMenus", distinctMenus);

        // topping
        let topping = {
          help: {},
        };
        if (toppingId) {
          topping = await fsService.fetchTopping(toppingId);
          console.log(`topping=${JSON.stringify(topping)}`);
        }
        this.commit("setTopping", topping);

      } catch (e) {
        console.error(e);
      } finally {
        this.commit("progress", false);
      }
    },
    async fetchToppings () {
      // console.log(`fetchToppings`);
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        const toppings = await fsService.fetchToppings();
        // console.log(`toppings=${JSON.stringify(toppings)}`);
        this.commit("setToppings", toppings);
      } catch (e) {
        console.error(e);
      }
      this.commit("progress", false);
    },
    async fetchTopping (context, { toppingId }) {
      // console.log(`fetchTopping: ${toppingId}`);
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        const topping = await fsService.fetchTopping(toppingId);
        // console.log(`topping=${JSON.stringify(topping)}`);
        // this.commit("setTopping", topping);
        return topping;
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async createTopping (context, { topping }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.createTopping(topping);

      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async updateTopping (context, { topping }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.updateTopping(topping);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    async deleteTopping (context, { toppingId }) {
      // console.log(`deleteTopping: ${toppingId}`);
      this.commit("progress", true);
      try {
        // DB
        const fsService = await this.dispatch("getFS");
        await fsService.deleteTopping(toppingId);
      } catch (e) {
        console.error(e);
      } finally {
        this.commit("progress", false);
      }
    },
    async copyTopping (context, { toppingId }) {
      this.commit("progress", true);
      try {
        // DB
        const fsService = await this.dispatch("getFS");
        await fsService.copyTopping(toppingId);

      } catch (e) {
        console.error(e);
      } finally {
        this.commit("progress", false);
      }
    },
    async updateToppingSort (context, { toppings }) {
      this.commit("progress", true);
      try {
        const fsService = await this.dispatch("getFS");
        await fsService.updateToppingSort(toppings);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.commit("progress", false);
      }
    },
    //-----------------------------------
    // 売上管理
    //-----------------------------------
    async fetchSales (context, { byDay, byWeek, byMonth, params }) {
      this.commit("progress", true);
      this.commit("salesChart", null);
      this.commit("salesTotal", null);
      try {
        // console.log("start");
        // await this.dispatch("sleep", { msec: 500 });
        // console.log("end");

        const [oldestYMD, chartData, total] = await Promise.all([
          // 最も古い注文データの日付
          this.dispatch("_fetchSalesOldestYMD"),

          // グラフデータ
          this.dispatch("_fetchSalesChartData", { byDay, byWeek, byMonth, params }),

          // 合計金額
          this.dispatch("_fetchSalesTotal"),
        ]);

        // グラフデータ
        let sales = {}
        sales.oldestYMD = oldestYMD
        if (byDay) {
          sales.type = CHART_TYPE.DATE;
          sales.data = chartData;
        } else if (byWeek) {
          sales.type = CHART_TYPE.WEEK;
          sales.data = chartData;
        } else if (byMonth) {
          sales.type = CHART_TYPE.MONTH;
          sales.data = chartData;
        }

        this.commit("salesChart", sales);
        this.commit("salesTotal", total);
      } catch (e) {
        console.error(e);
      } finally {
        this.commit("progress", false);
      }
    },
    async _fetchSalesOldestYMD () {
      // 店舗のサービス開始日
      const fsService = await this.dispatch("getFS");
      const startedAt = await fsService.fetchServiceStartedAt();
      const oldestYMD = startedAt
        ? moment(startedAt.toDate()).format('YYYY/M/D')
        : moment().format('YYYY/M/D')
      return oldestYMD;
    },
    async _fetchSalesChartData (context, { byDay, byWeek, byMonth, params }) {
      // - 直近14日 (14日前 <= createdAt)
      // - 直近10週 (10週前 <= createdAt)
      // - 直近12ヶ月 (12ヶ月前 <= createdAt)
      // - 指定月 (2022/1/1 <= createdAt <= 2022/1/31) ※「2022/1」指定の場合
      // - 指定年 (2022/1/1 <= createdAt <= 2022/12/31) ※「2022」指定の場合

      // const orders = await fsService.fetchOrdersByDateBefore(14); // 直近14日
      // const orders = await fsService.fetchOrdersByDateBefore(7 * 10); // 直近10週
      // const orders = await fsService.fetchOrdersByMonthBefore(12); // 直近12ヶ月
      // const orders = await fsService.fetchOrdersByMonth(2022, 7); // 指定月
      // const orders = await fsService.fetchOrdersByYear(2022); // 指定年
      // console.log(`orders=${JSON.stringify(orders)}`);
      const fsService = await this.dispatch("getFS");
      let chartData = {};
      let orders = [];
      let fromYMD = '';
      let toYMD = '';
      if (byDay) {
        // 日
        if (params) {
          const yyyy = params.yyyy;
          const mm = params.mm;
          [orders, fromYMD, toYMD] = await fsService.fetchOrdersByMonth(yyyy, mm); // 指定月(プルダウン)
        } else {
          [orders, fromYMD, toYMD] = await fsService.fetchOrdersByDateBefore(14); // 直近14日
        }
        chartData = createChartDataByDate({ orders, fromYMD, toYMD });
      } else if (byWeek) {
        // 週
        [orders, fromYMD, toYMD] = await fsService.fetchOrdersByDateBefore(7 * 10); // 直近10週
        chartData = createChartDataByWeek({ orders, fromYMD, toYMD });
      } else if (byMonth) {
        // 月
        let orders = [];
        if (params) {
          const yyyy = params.yyyy;
          [orders, fromYMD, toYMD] = await fsService.fetchOrdersByYear(yyyy); // 指定年(プルダウン)
        } else {
          [orders, fromYMD, toYMD] = await fsService.fetchOrdersByMonthBefore(12); // 直近12ヶ月
        }
        chartData = createChartDataByMonth({ orders, fromYMD, toYMD });
      }
      return chartData;
    },
    async _fetchSalesTotal () {
      const fsService = await this.dispatch("getFS");

      // 合計金額
      const totalOrders = await fsService.fetchOrdersForTotal();
      console.log("totalOrders", totalOrders)
      const total = {
        today: calcOrdersTotal(totalOrders.today),
        week: calcOrdersTotal(totalOrders.week),
        month: calcOrdersTotal(totalOrders.month),
      }
      return total;
    },
    //===================================
    // Storage
    //===================================
    async getStoragePath (context, { menuId }) {
      // 新規 shop/:shopId/_tmp_[auth#uid]
      // 編集 shop/:shopId/menus/:menuId
      let user = this.getters.user;
      if (!user) {
        await this.dispatch("initFS");
        user = this.getters.user;
      }
      // console.log(`[getStoragePath]user=${JSON.stringify(user)}`);
      const shopsRoot = user.shopsRoot;
      const shopId = user.shopId;
      if (!menuId) {
        return `${shopsRoot}/${shopId}/about`;
      }
      return `${shopsRoot}/${shopId}/menus/${menuId}`;
    },

    //===================================
    // 共通
    //===================================
    async sleep (context, { msec, }) {
      return new Promise(function (resolve) {
        setTimeout(resolve, msec);
      });
    }
  },
})
