import Firebase from 'firebase/compat/app';
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { GROUP_ID_DEFAULT } from "../constants";

class FirestoreService {
  constructor(db, uid) {
    this.db = db;
    this.uid = uid;
  }

  async init () {
    try {
      // console.log(`[init]uid=${this.uid}`);
      // ログインユーザ情報から初期設定取得
      const userSS = await this.db.doc(`users/${this.uid}`).get();
      const user = userSS.data();
      // console.log(`[init]user=${JSON.stringify(user)}`);

      const shopsRoot = user.shopsRoot;
      const shopId = user.shopId;

      this.shopsRoot = shopsRoot;
      this.shopId = shopId;
      this.root = `${shopsRoot}/${shopId}`;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  getUser () {
    return {
      shopsRoot: this.shopsRoot,
      shopId: this.shopId,
    };
  }

  //----------------------------------------
  // settings
  //----------------------------------------
  async fetchSettings () {
    const settingsSS = await this.db.doc(`${this.root}/settings/doc`).get();
    const settings = settingsSS.data();
    return settings ?? {};
  }

  async updateSettings (settings) {
    console.log(`[updateSettings]${JSON.stringify(settings)}`);
    try {
      const settingsRef = this.db.doc(`${this.root}/settings/doc`);

      // default
      settings.updatedAt = this.getServerTimestamp();

      // UPDATE
      return this.db.runTransaction(async (t) => {
        t.set(settingsRef, settings, { merge: true });
      });
    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  //----------------------------------------
  // about
  //----------------------------------------
  async fetchAbout () {
    const aboutSS = await this.db.doc(`${this.root}/about/doc`).get();
    const about = aboutSS.data() ?? {};

    // 臨時
    const daysTemporary = await this.fetchBusinessDaysTemporary();
    about.days_temporary = daysTemporary;

    return about;
  }

  async updateAbout (about) {
    console.log(`[updateAbout]${JSON.stringify(about)}`);
    try {
      const updatedAt = this.getServerTimestamp();
      about.updatedAt = updatedAt;

      // 受付状態 (初期値)
      if (about && !Object.prototype.hasOwnProperty.call(about, 'orderAcceptStatus')) {
        about.orderAcceptStatus = 0; // 受付停止中
      }

      return this.db.runTransaction(async (t) => {
        // about
        const aboutRef = this.db.doc(`${this.root}/about/doc`);
        t.set(aboutRef, about, { merge: true });
      });
    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  /**
   * サービス開始日 (店舗単位)
   * @returns 
   */
  async fetchServiceStartedAt () {
    const about = await this.fetchAbout()
    return about.serviceStartedAt;
  }

  /**
   * 
   * @returns 
   */
  async fetchBusinessDaysTemporary () {
    // 本日以降 (本日を含む)
    const today = moment().startOf('day').toDate();
    const startAt = Firebase.firestore.Timestamp.fromDate(today);
    console.log("today", today, startAt)

    const daysSS = await this.db
      .collection(`${this.root}/about/doc/days_temporary`)
      .orderBy("date", "asc")
      .startAt(startAt)
      .get();

    const days = daysSS.docs.map(docSS => {
      const day = docSS.data();
      return day;
    });

    return days;
  }

  async updateBusinessDays (daysEvery, daysTemporary) {
    // console.log(`[updateBusinessDays]`, daysEvery, daysTemporary);
    let dbData = {};
    try {
      const updatedAt = this.getServerTimestamp();
      dbData.updatedAt = updatedAt;
      dbData.updatedAtBusinessDays = updatedAt; // 更新検知させてバッチ再作成させる

      // 曜日別
      dbData.days_every = daysEvery;

      // 臨時
      const [delRefs, addDays, updDays] = await this._createDaysTemporaryParams(daysTemporary);

      return this.db.runTransaction(async (t) => {
        // 臨時 (削除)
        delRefs.forEach(delRef => t.delete(delRef));

        // 臨時 (追加)
        addDays.forEach(day => {
          const newRef = this.db.collection(`${this.root}/about/doc/days_temporary`).doc();
          day.id = newRef.id; // id 発行
          t.set(newRef, day);
        });

        // 臨時 (更新)
        updDays.forEach(day => {
          const updRef = this.db.doc(`${this.root}/about/doc/days_temporary/${day.id}`);
          t.set(updRef, day, { merge: true });
        });

        // 曜日別 (更新)
        const aboutRef = this.db.doc(`${this.root}/about/doc`);
        // console.log("dbData", dbData)
        t.set(aboutRef, dbData, { merge: true });
      });
    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  async _createDaysTemporaryParams (newDaysTemporary) {
    const newIds = newDaysTemporary.map(t => t.id);
    // console.log("newIds", newIds);

    // 今日以降の日付を対象にする理由
    // - データ量が増えた場合に検索に時間がかかる
    // - 今までの日付は DB 上に残しておく
    const oldDaysTemporary = await this.fetchBusinessDaysTemporary();
    const oldIds = oldDaysTemporary.map(t => t.id);
    // console.log("oldIds", oldIds);

    // 削除対象
    // 日付(新) に存在しない場合
    const delIds = [];
    oldIds.forEach(oldId => {
      if (!newIds.includes(oldId)) {
        delIds.push(oldId);
      }
    })
    // console.log("@del", delIds);

    let delRefs = [];
    const delRefTasks = delIds.map(async id => {
      const daysSS = await this.db.collection(`${this.root}/about/doc/days_temporary`)
        .where("id", "==", id)
        .get();
      const refs = daysSS.docs.map(docSS => docSS.ref);
      delRefs = delRefs.concat(refs);
    })
    await Promise.all(delRefTasks);
    // console.log("delRefs", delRefs);

    // 追加/更新対象
    // - 日付(旧) に存在する   -> 更新
    // - 日付(旧) に存在しない -> 追加
    const addDays = [];
    const updDays = [];
    newDaysTemporary.forEach(newDay => {
      if (oldIds.includes(newDay.id)) {
        updDays.push(newDay);
      } else {
        newDay.date = Firebase.firestore.Timestamp.fromDate(newDay.date); // Date -> Timestamp
        addDays.push(newDay);
      }
    })
    // console.log("@add/upd", addDays, updDays);
    return [delRefs, addDays, updDays];
  }

  //----------------------------------------
  // メニュー関係
  //----------------------------------------
  async fetchMenus () {
    // グルーピング前
    const menusRef = this.db.collection(`${this.root}/menus`);
    const menusSS = await menusRef.get();
    const menusData = menusSS.docs
      .map(docSS => {
        let data = docSS.data();
        data.id = docSS.id;
        return data;
      })
      .filter(data => "menuId" in data); // groups は除外
    // console.log(`menusData => ${JSON.stringify(menusData)}`);

    // グルーピング
    const groupMenuDatas = await this.createGroupMenuDatas(menusData);

    // グループ設定なし
    const noGroupMenuData = this.createNoGroupMenuData(menusData);

    // merge
    const mergedMenus = groupMenuDatas.concat([noGroupMenuData]);
    return mergedMenus;
  }

  async createGroupMenuDatas (menusData) {
    // グループデータ
    const menuGroups = await this.fetchMenuGroups();
    if (!menuGroups || menuGroups.length == 0) {
      return [];
    }
    const groups = menuGroups.groups;

    // グルーピング
    const newMenus = groups
      .sort((group1, group2) => group1.sort - group2.sort)
      .map((group) => {
        // sortable
        group.sortable = true;

        const groupId = group.id;
        const menus = menusData.filter((menu) => menu.groupIds.includes(groupId));

        // sort
        menus.sort((m1, m2) => {
          if (!m1.sorts || !m2.sorts) {
            return 0;
          }
          const m1Sort = m1.sorts.find(s => s.groupId === groupId);
          const m2Sort = m2.sorts.find(s => s.groupId === groupId);
          if (!m1Sort || !m2Sort) {
            return 0;
          }
          if (!Number.isInteger(m1Sort.sort) || !Number.isInteger(m2Sort.sort)) {
            return 0;
          }
          if (m1Sort.sort === m2Sort.sort) {
            // sort が同じ場合は updatedAt で比較
            const m1UpdatedAt = moment(m1.updatedAt.toDate());
            const m2UpdatedAt = moment(m2.updatedAt.toDate());
            return m1UpdatedAt.isBefore(m2UpdatedAt) ? 1 : -1;
          }
          return m1Sort.sort - m2Sort.sort;
        });
        // console.log(groupId, menus);

        return {
          group: group,
          menus: menus,
        };
      });
    return newMenus;
  }

  createNoGroupMenuData (menusData) {
    // カテゴリ未設定
    const noGroupMenus = menusData.filter(menu => menu.groupIds == null || menu.groupIds.length == 0);
    noGroupMenus.sort((m1, m2) => {
      // updatedAt
      if (!m1.updatedAt || !m2.updatedAt) {
        return 0;
      }
      const m1UpdatedAt = moment(m1.updatedAt.toDate());
      const m2UpdatedAt = moment(m2.updatedAt.toDate());
      return m1UpdatedAt.isBefore(m2UpdatedAt) ? 1 : -1;
    });

    // console.log(`noGroupMenus=${JSON.stringify(noGroupMenus)}`);
    const noGroupMenuData = {
      group: {
        id: GROUP_ID_DEFAULT,
        name: "その他",
        sort: 99999,
        sortable: false,
      },
      menus: noGroupMenus,
    };
    return noGroupMenuData;
  }

  // メニューグループ
  async fetchMenuGroups () {
    const docSS = await this.db
      .collection(`${this.root}/menus`)
      .doc("groups")
      .get();
    const menuGroups = docSS.data();
    return menuGroups;
  }

  async fetchToppings () {
    const toppingsSS = await this.db
      .collection(`${this.root}/toppings`)
      .orderBy("sort", "asc")
      // .orderBy("createdAt", "asc")
      .get();

    const toppings = toppingsSS.docs.map(docSS => {
      const topping = docSS.data();
      topping.toppingRef = docSS.ref;
      return topping;
    });
    return toppings;
  }

  async fetchTopping (toppingId) {
    const toppingSS = await this.db.doc(`${this.root}/toppings/${toppingId}`).get();
    let topping = toppingSS.data();
    delete topping.menu; // 削除(ref)
    delete topping.toppingRef; // 削除(ref)

    // 選択肢
    const values = await this.createToppingValues(topping.values);
    topping.values = values.filter(value => value != null);

    // 選択肢 初期値 (先頭)
    if (values && values.length != 0) {
      topping.selected = values[0].id;
    }
    return topping;
  }

  async createToppingValues (orgValues) {
    const newValuesTasks = orgValues.map(async value => {
      if (!value.ref) {
        return value; // 手入力
      }

      // 参照メニュー
      const refMenuId = value.refMenuId;
      const menuRef = this.db.doc(`${this.root}/menus/${refMenuId}`);
      const menuSS = await menuRef.get();
      if (!menuSS.exists) {
        return null; // 削除されている場合
      }

      // 必要な項目だけ抽出
      const refMenuData = menuSS.data();
      let newValue = {};
      newValue.id = value.id;
      newValue.refMenuId = refMenuId;
      newValue.name = refMenuData.name;
      newValue.provideStatus = refMenuData.provideStatus;
      newValue.amount = refMenuData.amountBaseEx; // 割引後の金額
      newValue.amountBase = refMenuData.amountBase;
      newValue.amountBaseEx = refMenuData.amountBaseEx;
      newValue.amountDiscount = refMenuData.amountDiscount;
      return newValue;
    });
    const newValues = await Promise.all(newValuesTasks);
    console.log("@newValues", newValues);
    return newValues.filter(v => v != null);
  }

  async createMenu (menu) {
    console.log(`[createMenu]${JSON.stringify(menu)}`);
    try {
      const menuId = menu.menuId;
      const menuRef = this.db.doc(`${this.root}/menus/${menuId}`);

      // createdAt
      const createdAt = this.getServerTimestamp();
      menu.date = createdAt; //TODO 利用箇所を createdAt に差し替えたら消す
      menu.createdAt = createdAt;
      menu.updatedAt = createdAt;
      menu.refUpdatedAt = createdAt;

      // sort
      const groupIds = menu.groupIds;
      const sorts = groupIds.map(groupId => {
        return {
          groupId: groupId,
          sort: 0, // default
        };
      });
      menu.sorts = sorts;

      // stock
      menu.stock = this.updateStock(menu);

      // INSERT
      return this.db.runTransaction(async (t) => {
        t.set(menuRef, menu);
      });
    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  async updateMenu (menu, updateMenuTimestamp) {
    console.log(`[updateMenu]${JSON.stringify(menu)}`, updateMenuTimestamp);
    try {
      const menuId = menu.menuId;
      const menuRef = this.db.doc(`${this.root}/menus/${menuId}`);

      // updatedAt
      const updatedAt = Firebase.firestore.FieldValue.serverTimestamp();
      if (updateMenuTimestamp) {
        console.log("[updateMenu]update menuTimestamp");
        // const updatedAt = this.getServerTimestamp();
        menu.date = updatedAt; //TODO 利用箇所を createdAt に差し替えたら消す
        menu.updatedAt = updatedAt;
        menu.refUpdatedAt = updatedAt;
      }

      // sort
      const currentSorts = menu.sorts;
      const groupIds = menu.groupIds;
      const sorts = groupIds.map(groupId => {
        let currentSort = null;
        if (currentSorts) {
          currentSort = currentSorts.find(s => s.groupId === groupId);
        }
        console.log(`groupId=${groupId}, currentSort=${currentSort}`);
        const sort = currentSort ? currentSort.sort : 0; //default
        return {
          groupId: groupId,
          sort: sort,
        };
      });
      menu.sorts = sorts;

      // stock
      menu.stock = this.updateStock(menu);

      // UPDATE
      await this.db.runTransaction(async (t) => {
        t.update(menuRef, menu);
      });

      // 以下を同じ updatedAt で更新する
      // - この menu を参照している選択肢を検索
      // - ヒットした選択肢の親 topping#refUpdatedAt
      // - ヒットした選択肢の親topping に紐づく menu#refUpdatedAt
      const toppingIds = await this.findToppingIdsByMenuId(menuId);
      if (toppingIds.length != 0) {
        const tasks = toppingIds.map(toppingId => this.updateLinkedMenusAndTopingOnlyDatetime(toppingId, updatedAt));
        await Promise.all(tasks);
      }
    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  async findToppingIdsByMenuId (menuId) {
    const toppings = await this.fetchToppings();
    const toppingIds = toppings
      .filter(topping => topping.values.some(v => v.refMenuId && v.refMenuId === menuId))
      .map(topping => topping.toppingId);
    console.log("@toppingIds", menuId, toppingIds);
    return toppingIds;
  }

  /**
   * メニューの updatedAt 更新するかどうか
   * @param {*} currentMenu 
   * @param {*} newMenu 
   * @returns 
   */
  isUpdateMenuTimestamp (currentMenu, newMenu) {
    // 基本情報
    const sameBaseInfo =
      currentMenu.name === newMenu.name // 名前
      // && currentMenu.about === newMenu.about // 説明
      && currentMenu.amountBase === newMenu.amountBase // 基本金額 (割引前)
      && currentMenu.amountBaseEx === newMenu.amountBaseEx // 基本金額 (割引後)
      && currentMenu.amountDiscount === newMenu.amountDiscount // 割引金額
      && currentMenu.amountBaseEx === newMenu.amountBaseEx // 基本金額 (割引後)
      // && currentMenu.xxx === newMenu.xxx // 税込
      // && currentMenu.xxx === newMenu.xxx // 税区分
      ;
    if (!sameBaseInfo) {
      console.log("different(baseInfo)");
      return true; // 更新する
    }

    // 売切情報
    const sameSoldout =
      currentMenu.provideStatus === newMenu.provideStatus
      && currentMenu.publish === newMenu.publish;
    if (!sameBaseInfo || !sameSoldout) {
      console.log("different(soldout)", sameBaseInfo, sameSoldout);
      return false; // 更新しない
    }

    // オプション
    const sameToppings = this.isSameToppings(currentMenu.toppings, newMenu.toppings);
    if (!sameToppings) {
      console.log("different(toppings)");
      return true; // 更新する
    }
    return false;
  }

  isSameToppings (currentToppings, newToppings) {
    console.log("isSameToppings", currentToppings, newToppings);

    // どちらもオプションなし
    if (currentToppings == null && newToppings == null) {
      console.log("same(toppings empty)");
      return true;
    }

    // 要素数の増減
    if (currentToppings == null && newToppings != null
      || currentToppings != null && newToppings == null
      || currentToppings.length != newToppings.length) {
      console.log("different(toppings length)");
      return false;
    }

    // 要素数が同じ場合
    // すべて同一 toppingId かどうか
    const sameIds = currentToppings.every(t => newToppings.some(newT => newT.toppingId === t.toppingId));
    if (!sameIds) {
      console.log("different(toppingId)");
      return false;
    }

    // 詳細比較は不要
    // メニュー画面ではオプションを つける/つけない しか選択不可

    return true;
  }

  async updateMenuSort (groupId, menus) {
    console.log(`[updateMenuSort]${groupId}, ${JSON.stringify(menus)}`);
    try {
      // メニュー(現在値)
      const currentMenuGroups = await this.fetchMenus();
      const targetData = currentMenuGroups.find(data => data.group.id === groupId);
      const currentMenus = targetData ? targetData.menus : {};
      // console.log(`menus(current)=${JSON.stringify(currentMenus)}`);

      //------------------------------
      // 更新用データ作成
      //------------------------------
      const updateDatas = menus.map(newMenu => {
        const menuId = newMenu.menuId;
        const newSort = newMenu.sort; // ソート順(新)

        // sorts(現在値) から ソート順(新) を作成
        const currentMenu = currentMenus.find(m => m.menuId === menuId);
        if (!currentMenu) {
          throw Error(`invalid menu: ${menuId}`);
        }
        console.log(`menu(current)=${JSON.stringify(currentMenu)}`);
        const currentSorts = currentMenu.sorts;
        const newSorts = currentSorts.map(s => {
          if (s.groupId == groupId) {
            s.sort = newSort;
          }
          return s;
        });
        console.log(`sorts(new)=${JSON.stringify(newSorts)}`);
        return {
          menuId: menuId,
          sorts: newSorts,
        };
      });
      console.log(`updateDatas=${JSON.stringify(updateDatas)}`);

      //------------------------------
      // 更新実行
      //------------------------------
      // const updatedAt = this.getServerTimestamp();
      return this.db.runTransaction(async (t) => {
        for (let target of updateDatas) {
          const menuId = target.menuId;
          const menuRef = this.db.doc(`${this.root}/menus/${menuId}`);
          console.log(`[update]${menuId} =>  ${JSON.stringify(target)}`);
          t.update(menuRef, {
            sorts: target.sorts,
            // updatedAt: updatedAt, // sort では更新しない
          });
        }
      });
    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  updateStock (menu) {
    const STOCK_DEFAULT = 10;
    const provideStatus = menu.provideStatus;
    return provideStatus === 0 ? STOCK_DEFAULT : 0;
  }

  async fetchMenu (menuId) {
    const menuSS = await this.db.doc(`${this.root}/menus/${menuId}`).get();
    const menu = menuSS.data();
    return menu;
  }

  async deleteMenu (menuId) {
    console.log(`[deleteMenu]${this.root}/menus/${menuId}`);
    return this.db.doc(`${this.root}/menus/${menuId}`).delete();
  }

  async addGroup (group) {
    console.log(`[addGroup]${JSON.stringify(group)}`);
    try {
      // id (新規発行)
      group.id = uuidv4();

      //------------------------------
      // INSERT or UPDATE
      //------------------------------
      const updatedAt = this.getServerTimestamp();
      const groupsRef = this.db.doc(`${this.root}/menus/groups`);
      return groupsRef.set({
        groups: Firebase.firestore.FieldValue.arrayUnion(group),
        updatedAt: updatedAt,
      }, { merge: true });

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateGroup (newGroup) {
    console.log(`[updateGroup]${JSON.stringify(newGroup)}`);
    try {
      const groupsPath = `${this.root}/menus/groups`;

      // 更新対象 group 取得 (配列要素)
      const groupsSS = await this.db.doc(groupsPath).get();
      const data = groupsSS.data();
      const groups = data.groups;
      if (!groups) {
        console.error(`groups is empty`);
        return;
      }
      const targetIndex = groups.findIndex(g => g.id === newGroup.id);
      if (targetIndex == -1) {
        console.error(`target is empty`);
        return;
      }

      groups[targetIndex] = newGroup;

      //------------------------------
      // UPDATE
      // (groups をまるごと差し替え)
      //------------------------------
      const updatedAt = this.getServerTimestamp();
      const groupsRef = this.db.doc(groupsPath);
      return groupsRef.set({
        groups: groups,
        updatedAt: updatedAt,
      });

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateGroupSort (groups) {
    console.log(`[updateGroupSort]${JSON.stringify(groups)}`);
    try {
      // カテゴリ(現在値)
      const groupsData = await this.fetchMenuGroups();
      const currentGroups = groupsData.groups;
      // console.log(`groups(current)=${JSON.stringify(currentGroups)}`);

      //------------------------------
      // 更新用データ作成
      //------------------------------
      const newGroups = groups.map(newGroup => {
        const groupId = newGroup.groupId;
        const newSort = newGroup.sort; // ソート順(新)

        // 現在値のソート順を更新
        const currentGroup = currentGroups.find(g => g.id === groupId);
        if (!currentGroup) {
          throw Error(`invalid menu: ${groupId}`);
        }
        currentGroup.sort = newSort;
        return currentGroup;
      });
      // console.log(`newGroups=${JSON.stringify(newGroups)}`);

      //------------------------------
      // UPDATE
      // (groups をまるごと差し替え)
      //------------------------------
      // const updatedAt = this.getServerTimestamp();
      const groupsPath = `${this.root}/menus/groups`;
      const groupsRef = this.db.doc(groupsPath);
      return groupsRef.set({
        groups: newGroups,
        // updatedAt: updatedAt, // sort では更新しない
      });

    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  async deleteGroup (groupId) {
    console.log(`[deleteGroup]${groupId}`);
    try {
      const groupsPath = `${this.root}/menus/groups`;

      // 削除対象 group 取得 (配列要素)
      const groupsSS = await this.db.doc(groupsPath).get();
      const data = groupsSS.data();

      const groups = data.groups;
      if (!groups) {
        console.error(`groups is empty`);
        return;
      }

      const target = groups.find(g => g.id === groupId);
      if (!target) {
        console.error(`target is empty`);
        return;
      }

      // menus/groups
      // 「該当 groupId」だけ削除
      await this.db.collection(`${this.root}/menus`)
        .where("groupIds", "array-contains", target.id)
        .get()
        .then((menuSS) => {
          menuSS.forEach((docSS) => {
            docSS.ref.update({
              groupIds: Firebase.firestore.FieldValue.arrayRemove(groupId),
              date: this.getServerTimestamp(),
            });
          });
        });

      //------------------------------
      // DELETE
      // (groups をまるごと差し替え)
      //------------------------------
      const newGroups = groups
        .filter(group => group.id !== target.id) // 削除要素以外
        .map((group, index) => {
          group.sort = index; // index 順
          return group;
        });
      await this.db.doc(groupsPath).set({
        groups: newGroups,
      }, { merge: true });

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async createTopping (topping) {
    console.log(`[createTopping] ${JSON.stringify(topping)}`);
    try {
      const toppingId = topping.toppingId;
      const toppingRef = this.db.doc(`${this.root}/toppings/${toppingId}`);

      // 選択肢 (先頭項目を LIFF 上での初期選択とする)
      const values = topping.values;
      if (!values || values.length == 0) {
        // values 必須
        throw Error(`invalid values(empty)`);
      }
      const valueId = topping.values[0].id;

      // default
      const createdAt = this.getServerTimestamp();
      topping.createdAt = createdAt;
      topping.updatedAt = createdAt;
      topping.refUpdatedAt = createdAt;
      topping.type = "radio";
      topping.selected = valueId;
      topping.sort = 0; // 先頭

      //----------------------------------------
      // 既存オプションの sort++
      //----------------------------------------
      const _toppings = await this.fetchToppings();
      const targetToppings = _toppings
        .map(t => {
          t.sort++;
          return t;
        });

      return this.db.runTransaction(async (t) => {
        // UPDATE (既存オプション)
        for (let target of targetToppings) {
          const ref = this.db.doc(`${this.root}/toppings/${target.toppingId}`);
          // target.updatedAt = updatedAt; // sort では更新しない
          t.update(ref, target);
        }

        // INSERT
        t.set(toppingRef, topping);
      });
    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  async updateTopping (topping) {
    console.log(`[updateTopping]${JSON.stringify(topping)}`);
    try {
      // updatedAt
      const updatedAt = this.getServerTimestamp();
      topping.updatedAt = updatedAt;
      topping.refUpdatedAt = updatedAt;

      const toppingId = topping.toppingId;

      // topping に紐づく menu 抽出
      const menuRefs = await this.extractMenuRefsByToppingId(toppingId);

      // UPDATE
      const toppingRef = this.db.doc(`${this.root}/toppings/${toppingId}`);
      return this.db.runTransaction(async (t) => {
        // topping, 紐づくメニュー
        t.update(toppingRef, topping);
        for (const menuRef of menuRefs) {
          t.update(menuRef, { refUpdatedAt: updatedAt });
        }
      });
    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  /**
   * 日付時刻のみ更新 (topping と topping に紐づく menu)
   * @param {*} toppingId 
   * @param {*} updatedAt 
   * @returns 
   */
  async updateLinkedMenusAndTopingOnlyDatetime (toppingId, updatedAt) {
    console.log(`[updateLinkedMenusAndTopingOnlyDatetime]${toppingId}`);
    try {
      // topping に紐づく menu 抽出
      const menuRefs = await this.extractMenuRefsByToppingId(toppingId);

      // UPDATE
      const toppingRef = this.db.doc(`${this.root}/toppings/${toppingId}`);
      return this.db.runTransaction(async (t) => {
        // topping, 紐づくメニュー (updatedAt のみ)
        t.update(toppingRef, { refUpdatedAt: updatedAt });
        for (const menuRef of menuRefs) {
          t.update(menuRef, { refUpdatedAt: updatedAt });
        }
      });
    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  async extractMenuRefsByToppingId (toppingId) {
    console.log(`[extractMenuRefsByToppingId]${toppingId}`);

    // topping に紐づく menu 抽出
    // (抽出用に menu/topping 要素と同じものを作成)
    const target = {
      toppingId,
      toppingRef: this.db.doc(`${this.root}/toppings/${toppingId}`),
    };
    const menusSS = await this.db.collection(`${this.root}/menus`)
      .where("toppings", "array-contains", target)
      .get();
    const menuRefs = menusSS.docs.map(docSS => docSS.ref);
    console.log("@menuRefs", menuRefs);
    return menuRefs;
  }

  async deleteTopping (toppingId) {
    console.log(`[deleteTopping]${this.root}/toppings/${toppingId}`);
    try {
      // updatedAt
      const updatedAt = this.getServerTimestamp();

      // topping に紐づく menu 抽出
      // (抽出用に menu/topping 要素と同じものを作成)
      const toppingRef = this.db.doc(`${this.root}/toppings/${toppingId}`);
      const target = {
        toppingId: toppingId,
        toppingRef: toppingRef,
      };
      const menusSS = await this.db.collection(`${this.root}/menus`)
        .where("toppings", "array-contains", target)
        .get();
      const menuRefs = menusSS.docs.map(docSS => docSS.ref);
      // console.log("@menuRefs", menuRefs);

      // DELETE
      return this.db.runTransaction(async (t) => {
        t.delete(toppingRef);

        // 紐づくメニュー
        // - 「該当 topping」だけ削除
        // - updatedAt 更新
        const menuTasks = menuRefs.map(
          menuRef => t.update(menuRef, {
            toppings: Firebase.firestore.FieldValue.arrayRemove(target),
            refUpdatedAt: updatedAt,
          }));
        await Promise.all(menuTasks);
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async copyTopping (toppingId) {
    try {
      //----------------------------------------
      // 新規作成 (コピー要素)
      //----------------------------------------
      // コピー元取得
      // --> values (参照メニュー) はすべて展開済みの値なので注意
      const orgTopping = await this.fetchTopping(toppingId)
      console.log("copyTopping", orgTopping);
      const orgSort = orgTopping.sort;

      // newTopping
      const newTopping = orgTopping;

      // toppingId (再発行)
      const newToppingId = uuidv4();
      newTopping.toppingId = newToppingId;

      // 名前
      newTopping.name = `${newTopping.name} copy`

      // 選択肢
      const values = newTopping.values;
      if (!values || values.length == 0) {
        // values 必須
        throw Error(`invalid values(empty)`);
      }
      const newValues = newTopping.values.map(v => {
        return this.createCopyToppingValue(v);
      })
      newTopping.values = newValues;

      // 先頭項目を LIFF 上での初期選択とする
      const selectedValueId = newTopping.values[0].id;

      // default
      const createdAt = this.getServerTimestamp();
      newTopping.createdAt = createdAt;
      newTopping.updatedAt = createdAt;
      newTopping.refUpdatedAt = createdAt;
      newTopping.selected = selectedValueId;
      newTopping.sort = orgSort + 1; // コピー元の次

      //----------------------------------------
      // 既存オプションの sort 更新
      // コピー元より大きいものだけ sort++
      //----------------------------------------
      const _toppings = await this.fetchToppings();
      const targetToppings = _toppings
        .filter(t => t.sort > orgSort)
        .map(t => {
          t.sort++;
          return t;
        })

      return this.db.runTransaction(async (t) => {
        // UPDATE (既存オプション)
        for (let target of targetToppings) {
          const ref = this.db.doc(`${this.root}/toppings/${target.toppingId}`);
          // target.updatedAt = updatedAt; // sort では更新しない
          t.update(ref, target);
        }

        // INSERT (コピー)
        const toppingRef = this.db.doc(`${this.root}/toppings/${newToppingId}`);
        newTopping.toppingRef = toppingRef;
        t.set(toppingRef, newTopping);
      });

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  createCopyToppingValue (orgValue) {
    if (orgValue.refMenuId) {
      // 参照メニュー
      let newValue = {};
      newValue.id = uuidv4(); // value id (再発行)
      newValue.ref = true;
      newValue.refMenuId = orgValue.refMenuId;
      return newValue;
    } else {
      // 手入力
      let newValue = orgValue; // 要素はそのままコピー
      newValue.id = uuidv4(); // value id (再発行)
      return newValue;
    }
  }

  async updateToppingSort (toppings) {
    // console.log(`[updateToppingSort]${JSON.stringify(toppings)}`);
    try {
      // オプション(現在値)
      const currentToppings = await this.fetchToppings();
      // console.log(`toppings(current)=${JSON.stringify(currentToppings)}`);

      //------------------------------
      // 更新用データ作成
      //------------------------------
      const newToppings = toppings.map(newTopping => {
        const toppingId = newTopping.toppingId;
        const newSort = newTopping.sort; // ソート順(新)

        // 現在値のソート順を更新
        const currentTopping = currentToppings.find(t => t.toppingId === toppingId);
        if (!currentTopping) {
          throw Error(`invalid topping: ${toppingId}`);
        }
        currentTopping.sort = newSort;
        return currentTopping;
      });
      // console.log(`newToppings=${JSON.stringify(newToppings)}`);

      //------------------------------
      // UPDATE
      //------------------------------
      // const updatedAt = this.getServerTimestamp();
      return this.db.runTransaction(async (t) => {
        for (let target of newToppings) {
          const toppingId = target.toppingId;
          const toppingRef = this.db.doc(`${this.root}/toppings/${toppingId}`);
          // target.updatedAt = updatedAt; // sort では更新しない
          t.update(toppingRef, target);
        }
      });
    } catch (e) {
      console.error(e);
      throw new Error("transaction failed", e);
    }
  }

  //----------------------------------------
  // 注文履歴
  //----------------------------------------
  async fetchOrders () {
    // 全件
    const ordersRef = this.db.collection(`${this.root}/orders`);
    const ordersSS = await ordersRef.get();
    const ordersData = ordersSS.docs.map(docSS => docSS.data())
    return ordersData;
  }

  /**
   * 注文履歴 (直近 指定日数分)
   * @param {*} beforeDays 指定日数
   * @returns 
   */
  async fetchOrdersByDateBefore (beforeDays) {
    const before = moment().subtract(beforeDays, 'days').startOf('day').toDate();
    const startAt = Firebase.firestore.Timestamp.fromDate(before);
    // console.log("fetchOrdersByDateBefore", before)

    const ordersRef = this.db.collection(`${this.root}/orders`)
      .orderBy('createdAt')
      .startAt(startAt);
    const ordersSS = await ordersRef.get();
    // const ordersData = ordersSS.docs.map(docSS => docSS.data())
    const ordersData = this._createOrdersDataForChart(ordersSS);

    const fromYMD = moment(before).format('YYYYMMDD')
    const toYMD = moment().format('YYYYMMDD')
    return [ordersData, fromYMD, toYMD]
  }

  /**
   * 注文履歴 (直近 指定月数分)
   * @param {*} beforeMonths 指定月数
   * @returns 
   */
  async fetchOrdersByMonthBefore (beforeMonths) {
    const before = moment().subtract(beforeMonths, 'months').startOf('month').toDate();
    const startAt = Firebase.firestore.Timestamp.fromDate(before);
    // console.log("fetchOrdersByMonthBefore", before)

    const ordersRef = this.db.collection(`${this.root}/orders`)
      .orderBy('createdAt')
      .startAt(startAt);
    const ordersSS = await ordersRef.get();
    const ordersData = this._createOrdersDataForChart(ordersSS);

    const fromYMD = moment(before).format('YYYYMMDD')
    const toYMD = moment().format('YYYYMMDD')
    return [ordersData, fromYMD, toYMD]
  }

  /**
   * 注文履歴 (指定年月)
   * @param {*} year 指定年(YYYY)
   * @param {*} month 指定月(M)
   * @returns 
   */
  async fetchOrdersByMonth (year, month) {
    const targetYM = moment(`${year}/${month}`, 'YYYY/M');
    const start = targetYM.startOf('month').toDate();
    const end = targetYM.endOf('month').toDate();
    const startAt = Firebase.firestore.Timestamp.fromDate(start);
    const endAt = Firebase.firestore.Timestamp.fromDate(end);
    // console.log("fetchOrdersByMonth", start, end)

    const ordersRef = this.db.collection(`${this.root}/orders`)
      .orderBy('createdAt')
      .startAt(startAt)
      .endAt(endAt);
    const ordersSS = await ordersRef.get();
    const ordersData = this._createOrdersDataForChart(ordersSS);

    const fromYMD = moment(start).format('YYYYMMDD')
    const toYMD = moment(end).format('YYYYMMDD')
    return [ordersData, fromYMD, toYMD]
  }

  /**
   * 注文履歴 (直近 指定年)
   * @param {*} year 指定年(YYYY)
   * @returns 
   */
  async fetchOrdersByYear (year) {
    const targetY = moment(`${year}`, 'YYYY');
    const start = targetY.startOf('year').toDate();
    const end = targetY.endOf('year').toDate();
    const startAt = Firebase.firestore.Timestamp.fromDate(start);
    const endAt = Firebase.firestore.Timestamp.fromDate(end);
    // console.log("fetchOrdersByYear", start, end)

    const ordersRef = this.db.collection(`${this.root}/orders`)
      .orderBy('createdAt')
      .startAt(startAt)
      .endAt(endAt);
    const ordersSS = await ordersRef.get();
    const ordersData = this._createOrdersDataForChart(ordersSS);

    const fromYMD = moment(start).format('YYYYMMDD')
    const toYMD = moment(end).format('YYYYMMDD')
    return [ordersData, fromYMD, toYMD]
  }

  /**
   * 注文履歴 (今日)
   * @returns 
   */
  async fetchOrdersByToday () {
    const [orders, ,] = await this.fetchOrdersByDateBefore(0)
    return orders
  }

  /**
   * 注文履歴 (今週)
   * @returns 
   */
  async fetchOrdersByThisWeek () {
    const now = moment().startOf('day'); // 本日 0:00
    const weekStartDay = moment().startOf('week'); // 週頭 0:00
    const diff = now.diff(weekStartDay, 'days')
    const [orders, ,] = await this.fetchOrdersByDateBefore(diff)
    return orders
  }

  /**
   * 注文履歴 (今月)
   * @returns 
   */
  async fetchOrdersByThisMonth () {
    const now = moment().startOf('day'); // 本日 0:00
    const yyyy = now.format('YYYY')
    const mm = now.format('M')
    const [orders, ,] = await this.fetchOrdersByMonth(yyyy, mm)
    return orders
  }

  _createOrdersDataForChart (ordersSS) {
    const ordersData = ordersSS.docs.map(docSS => {
      const data = docSS.data();
      const createdAt = data.createdAt;
      const _key_ym = moment(createdAt.toDate()).format('YYYY/M') // for summary
      const _key_ymd = moment(createdAt.toDate()).format('YYYYMMDD') // for summary
      const _key_weekNo = moment(createdAt.toDate()).week() // for summary (週番号 日曜スタート)
      return {
        orderNo: data.orderNo,
        paid: data.paid,
        amount: data.amount,
        orderStatus: data.orderStatus,
        wayOfOrder: data.wayOfOrder,
        wayOfPay: data.wayOfPay,
        // for chart
        _key_ym, _key_ymd, _key_weekNo,
      }
    })
    return ordersData;
  }

  /**
   * 注文履歴 (最も古い注文1件のみ)
   * @returns 
   */
  async fetchOldestOrder () {
    const ordersRef = this.db.collection(`${this.root}/orders`)
      .orderBy('createdAt', "asc")
      .limit(1)
    const ordersSS = await ordersRef.get();
    const ordersData = ordersSS.docs.map(docSS => docSS.data())
    return ordersData[0];
  }

  /**
   * 注文履歴 (トータル金額 計算用)
   */
  async fetchOrdersForTotal () {
    const [today, week, month] = await Promise.all([
      this.fetchOrdersByToday(), // 今日
      this.fetchOrdersByThisWeek(), // 今週
      this.fetchOrdersByThisMonth(), // 今月
    ])
    return { today, week, month, }
  }

  //----------------------------------------
  // 共通
  //----------------------------------------
  async sleep (msec) {
    return new Promise(function (resolve) {
      setTimeout(resolve, msec);
    });
  }

  getServerTimestamp () {
    return Firebase.firestore.FieldValue.serverTimestamp();
  }
}

export default FirestoreService;
