export const GROUP_ID_DEFAULT = "_____NOTHING_____";
export const CHOICE_ID_DEFAULT = "_____CHOICE_NOTHING_____";

// 割引状態
export const DISCOUNT_STATUS = [
  { id: 0, name: "なし" },
  { id: 1, name: "割引中" },
];
export const DISCOUNT_STATUS_ID_NOTHING = 0;
export const DISCOUNT_STATUS_ID_ON = 1;

// 提供状態
export const PROVIDE_STATUS = [
  { id: 0, name: "提供可能" },
  { id: 1, name: "本日完売" },
  { id: 2, name: "再販売未定" },
];

// 注文状態
export const ORDER_STATUS = {
  ACCEPTED: 0, //新規注文
  COOKING: 1, //調理中
  WAITING: 2, //準備完了
  RECEIVED: 3, //受取済
  CANCEL: 4, //キャンセル
};

// 受付状態 (店舗)
export const ORDER_ACCEPT_STATUS = [
  { id: 0, name: "受付停止中" },
  { id: 1, name: "一時停止中" },
  { id: 2, name: "受付中" },
];

// 売上管理 グラフ種別
export const CHART_TYPE = {
  DATE: 0,
  WEEK: 1,
  MONTH: 2,
};

// 営業日種別
export const BUSINESS_DAY_TYPE = {
  HOLIDAY: 0, // 休日
  BIZ_ONE_TIME: 1, // 1日1回営業
  BIZ_TWO_TIMES: 2, // 1日2回営業
}
