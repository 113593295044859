import { storage } from "../plugins/firebase";
import moment from "moment";

export const uploadFile = async (fullPath, file) => {
  console.log(`[storage]upload: ${fullPath}`);
  const fileRef = storage.child(`${fullPath}`);
  await fileRef.put(file);
  return {
    "fullPath": fileRef.fullPath,
    "url": await fileRef.getDownloadURL(),
  };
};

export const deleteFile = async (fullPath) => {
  console.log(`[storage]delete: ${fullPath}`);
  return storage.child(`${fullPath}`).delete();
};

export const cleanFiles = async (dirPath) => {
  console.log(`[storage]clean: ${dirPath}`);
  const resFiles = await storage.child(`${dirPath}`).listAll();
  const resThumbs = await storage.child(`${dirPath}/thumbs`).listAll();
  return Promise.all([
    resFiles.items.map(ref => ref.delete()),
    resThumbs.items.map(ref => ref.delete()),
  ]);
};

export const fetchAllFiles = async (rootPath) => {
  console.log("fetchAllFiles", rootPath);
  let targetPath = "";
  if (rootPath) {
    targetPath = rootPath;
  }
  const list = await storage.child(`${targetPath}`).listAll();
  const fileTasks = list.items.map(async fileRef => {
    const meta = await fileRef.getMetadata();
    return {
      "fullPath": fileRef.fullPath,
      "url": await fileRef.getDownloadURL(),
      "updated": meta.updated, // 2022-01-01T00:00:00.000Z
    }
  })
  const files = await Promise.all(fileTasks);

  // sort (更新順)
  files.sort((f1, f2) => {
    const f1Updated = moment(f1.updated);
    const f2Updated = moment(f2.updated);
    return f1Updated.isBefore(f2Updated) ? -1 : 1;
  })

  console.log("fetchAllFiles", files);
  return files;
};
